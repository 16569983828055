import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import * as firebase from 'firebase';
import { RotateSpinLoader } from 'react-css-loaders';
import Loadable from 'react-loadable';

  let config = {
    apiKey: "AIzaSyADlia3b2kYkcLfQeeDNYmLz_Gj45r08T4",
    authDomain: "evercertify.firebaseapp.com",
    databaseURL: "https://evercertify.firebaseio.com",
    projectId: "evercertify",
    storageBucket: "evercertify.appspot.com",
    messagingSenderId: "322626180113"
  };
  firebase.initializeApp(config);

const LoadableComponent = Loadable({
  loader: () => import('./App.js'),
  loading: RotateSpinLoader
});

ReactDOM.render(<LoadableComponent />, document.getElementById('root'));
registerServiceWorker();
